<template>
  <div class="flex items-center bg-black h-screen justify-center">
    <div class="grid grid-rows-3 grid-flow-col gap-4 text-gray-500 align-middle">
      <div class="col-span-auto flex items center justify-center">
        <img alt="Vue logo" class="max-h-48" src="./assets/logo_new.webp" />
      </div>
      <div class="col-span-auto flex items center justify-center gap-2">
        <span class="text-3xl">Welcome to </span><span class="text-3xl text-white font-bold"> azeitona.tech</span>
      </div>
      <div class="col-span-auto text-gray-500 flex items-top justify-center">We are under construction</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
}
</script>
